import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            status: 0,
            vat: '',
            database: '',
            lang: 0,
            translations: '',
            reservation_pms_id: 0,
            reservation_id: 0,
            reservation_room_id: 0,
            reservation_data: '',
            company_id: 1,
            company_info: '',
            countries: [],
            cities: [],
            identity_documents: [],
            guests: 1
        }
    },
    mutations: {
        setStatus(state, status) {
            state.status = status;
        },
        setVat(state, vat) {
            state.vat = vat;
        },
        setDatabase(state, database) {
            state.database = database;
        },
        setLang(state, lang) {
            state.lang = lang;
        },
        setLangByCode(state, lang) {
            switch(lang) {
                case 'IT':
                    state.lang = 0;
                    break;
                case 'EN':
                    state.lang = 1;
                    break;
                case 'DE':
                    state.lang = 2;
                    break;
                case 'FR':
                    state.lang = 3;
                    break;
                case 'PT':
                    state.lang = 4;
                    break;
                case 'ES':
                    state.lang = 5;
                    break;
                case 'RU':
                    state.lang = 6;
                    break;
                case 'CH':
                    state.lang = 7;
                    break;
            }
        },
        setTranslations(state, translations) {
            state.translations = translations;
        },
        setReservationPmsID(state, reservation_pms_id) {
            state.reservation_pms_id = reservation_pms_id;
        },
        setReservationID(state, reservation_id) {
            state.reservation_id = reservation_id;
        },
        setReservationRoomID(state, reservation_room_id) {
            state.reservation_room_id = reservation_room_id;
        },
        setReservationData(state, reservation_data) {
            state.reservation_data = reservation_data;
        },
        setCompanyID(state, company_id) {
            state.company_id = company_id;
        },
        setCompanyInfo(state, company_info) {
            state.company_info = company_info;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        setIdentityDocuments(state, identity_documents) {
            state.identity_documents = identity_documents;
        },
        setGuests(state, guests) {
            state.guests = guests;
        },
    },
    getters: {
        get_lang_code: state => {
            switch(state.lang) {
                case 0:
                    return 'IT';
                case 1:
                    return 'EN';
                case 2:
                    return 'DE';
                case 3:
                    return 'FR';
                case 4:
                    return 'PT';
                case 5:
                    return 'ES';
                case 6:
                    return 'RU';
                case 7:
                    return 'CH';
                default:
                    return 'IT';
            }
        },
        get_date_lang_code: state => {
            switch(state.lang) {
                case 0:
                    return 'it-IT';
                case 1:
                    return 'en-GB';
                case 2:
                    return 'de-DE';
                case 3:
                    return 'fr-FR';
                case 4:
                    return 'pt-PT';
                case 5:
                    return 'es-ES';
                case 6:
                    return 'ru-RU';
                case 7:
                    return 'ja-JP';
                default:
                    return 'en-GB';
            }
        },
        get_translation: (state, getters) => (key) => {
            let string = '';
            if(state.translations) {
                string = state.translations[key][getters.get_lang_code];
                if(string == '') {
                    string = state.translations[key]['EN'];
                }
                if(string == '') {
                    string = state.translations[key]['IT'];
                }
            }
            return string;  
        },
    },
    actions: {

    }
})