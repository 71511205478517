<template>
	<div class="checkin-success">
		<CompanyInfos v-if="status != -3 && status != -4"></CompanyInfos>
		<hr class="divider" v-if="status != -3 && status != -4" />
		<ReservationInfos></ReservationInfos>
		<FeedbackMessage :color="'DA0000'" :text="error_text"></FeedbackMessage>
	</div>
</template>

<script>
	import CompanyInfos from '@/components/CompanyInfos.vue'
	import ReservationInfos from '@/components/ReservationInfos.vue'
	import FeedbackMessage from '@/components/FeedbackMessage.vue'

	export default {
		name: 'CheckInError',
		components: {
			CompanyInfos,
			ReservationInfos,
			FeedbackMessage
		},
		computed: {
			status: function() {
				return this.$store.state.status;
			},
			error_text: function() {
				if(this.$store.state.status == -1) {
					return this.getTranslation('WEB_CHECK_IN_ERROR');
				} else if(this.$store.state.status == -2) {
					return this.getTranslation('WEB_CHECK_IN_PAST_DATE');
				} else if(this.$store.state.status == -3) {
					return this.getTranslation('WEB_CHECK_IN_RESERVATION_INVALID');
				} else if(this.$store.state.status == -4) {
					return this.getTranslation('WEB_CHECK_IN_URL_INVALID');
				}
				return '';
			}
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
		}
	}
</script>

<style type="text/css" scoped>
	.checkin-success {
		width: calc(100% - 100px);
		margin: 0 auto;
	}

	.divider {
		border: none;
		border-top: 3px solid var(--mainColor);
		margin-top: 20px;
		margin-bottom: 30px;
	}
</style>