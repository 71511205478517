<template>
    <div class="main">
        <CheckIn v-if="status == 1"></CheckIn>
        <CheckInSuccess v-if="status > 1"></CheckInSuccess>
        <CheckInError v-if="status < 0"></CheckInError>
    </div>
</template>

<script>
import CheckIn from './views/CheckIn.vue'
import CheckInSuccess from './views/CheckInSuccess.vue'
import CheckInError from './views/CheckInError.vue'

export default {
    name: 'App',
        components: {
            CheckIn,
            CheckInSuccess,
            CheckInError
        },
        inject: ['$axios'],
        computed: {
            status: function() {
                return this.$store.state.status;
            }
        },
        mounted: function() {
            let self = this

            let urlParams = new URLSearchParams(window.location.search);

            let vat = urlParams.get('hJ32');
            self.$store.commit('setVat', vat);

            let reservation_pms_id = urlParams.get('03Ec');
            self.$store.commit('setReservationPmsID', reservation_pms_id);

            let reservation_room_id = urlParams.get('vRea');
            self.$store.commit('setReservationRoomID', reservation_room_id);

            let guests = urlParams.get('kK31');
            self.$store.commit('setGuests', guests);

            let lang = urlParams.get('qQsx');
            self.$store.commit('setLangByCode', lang);

            let control = urlParams.get('c');

            self.$axios({
                method: 'get',
                url: '/getHotelDatabase/'+vat
            }).then(function (response) {
                let hotelDB = response.data.trim();
                self.$store.commit('setDatabase', hotelDB);

                self.$axios({
                    method: 'get',
                    url: '/checkControlString/'+reservation_pms_id+'/'+vat+'/'+control
                }).then(function (response) {
                    if(response.data == 1) {
                        if(self.$store.state.database != 'HOTELSNOBOOKING') {
                            self.$axios({
                                method: 'get',
                                url: '/checkin/'+self.$store.state.database+'/getReservationData/'+self.$store.state.reservation_pms_id+'/'+self.$store.state.reservation_room_id
                            }).then(function (response) {
                                self.$store.commit('setReservationID', response.data.reservation_id);
                                delete response.data.reservation_id;
                                self.$store.commit('setCompanyID', response.data.company_id);
                                delete response.data.company_id;
                                self.$store.commit('setReservationData', response.data);

                                self.$axios({
                                    method: 'get',
                                    url: '/booking/'+self.$store.state.database+'/getCompanyInfo/'+self.$store.state.company_id
                                }).then(function (response) {
                                    self.$store.commit('setCompanyInfo', response.data);
                                });

                                self.$axios({
                                    method: 'get',
                                    url: '/booking/'+self.$store.state.database+'/getBackgroundAndColors/'+self.$store.state.company_id
                                }).then(function (response) {
                                    if(response.data.colors != null && response.data.colors.mainColor != null) {
                                        document.documentElement.style.setProperty('--mainColor', response.data.colors.mainColor);
                                        document.documentElement.style.setProperty('--mainColorTrasparence', response.data.colors.mainColor+'33');
                                        document.documentElement.style.setProperty('--litepickerDayIsStartBg', response.data.colors.mainColor);
                                        document.documentElement.style.setProperty('--litepickerDayIsEndBg', response.data.colors.mainColor);
                                        document.documentElement.style.setProperty('--litepickerDayColorHover', response.data.colors.mainColor);
                                        document.documentElement.style.setProperty('--litepickerDayIsInRange', response.data.colors.mainColor+'33');
                                    } else {
                                        document.documentElement.style.setProperty('--mainColor', '#2196f3');
                                        document.documentElement.style.setProperty('--mainColorTransparence', '#2196f333');
                                        document.documentElement.style.setProperty('--litepickerDayIsStartBg', '#2196f3');
                                        document.documentElement.style.setProperty('--litepickerDayIsEndBg', '#2196f3');
                                        document.documentElement.style.setProperty('--litepickerDayColorHover', '#2196f3');
                                        document.documentElement.style.setProperty('--litepickerDayIsInRange', '#2196f3'+'33');
                                    }

                                    if(response.data.colors != null && response.data.colors.backgroundColor != null) {
                                        document.documentElement.style.setProperty('--backgroundColor', response.data.colors.backgroundColor);
                                    } else {
                                        document.documentElement.style.setProperty('--backgroundColor', '#eee');
                                    }
                                    
                                    if(response.data.background != '') {
                                        document.documentElement.style.setProperty('--backgroundImage', 'url("' + encodeURI(self.$baseURL + '/structures/'+self.$company+'/'+self.$store.state.company_id+'/company/' + response.data.background) + '")');
                                    }

                                    if(self.$store.state.reservation_id == -1) {
                                        self.$store.commit('setStatus', -3);
                                    } else if(self.$store.state.reservation_data.check_in_done > 0) {
                                        self.$store.commit('setStatus', 3);
                                    } else if(self.$store.state.reservation_data.current_date > self.$store.state.reservation_data.check_in) {
                                        self.$store.commit('setStatus', -2);
                                    } else {
                                        self.$store.commit('setStatus', 1);
                                    }                     
                                });
                            });
                        } else {
                            self.$axios({
                                method: 'get',
                                url: '/checkin/'+self.$store.state.database+'/getCompanyNoBookingInfos/'+self.$store.state.vat
                            }).then(function (response) {
                                self.$store.commit('setCompanyInfo', response.data);
                            });

                            document.documentElement.style.setProperty('--mainColor', '#2196f3');
                            document.documentElement.style.setProperty('--mainColorTransparence', '#2196f333');
                            document.documentElement.style.setProperty('--litepickerDayIsStartBg', '#2196f3');
                            document.documentElement.style.setProperty('--litepickerDayIsEndBg', '#2196f3');
                            document.documentElement.style.setProperty('--litepickerDayColorHover', '#2196f3');
                            document.documentElement.style.setProperty('--litepickerDayIsInRange', '#2196f3'+'33');
                            document.documentElement.style.setProperty('--backgroundColor', '#eee');

                            self.$axios({
                                method: 'get',
                                url: '/checkin/'+self.$store.state.database+'/getReservationNoBookingData/'+self.$store.state.reservation_pms_id+'/'+self.$store.state.reservation_room_id
                            }).then(function (response) {
                                self.$store.commit('setReservationData', response.data);

                                if(self.$store.state.reservation_data.check_in_done > 0) {
                                    self.$store.commit('setStatus', 3);
                                } else {
                                    self.$store.commit('setStatus', 1);
                                }
                            })
                        }

                        self.$axios({
                            method: 'get',
                            url: '/booking/'+self.$store.state.database+'/getCountries/'+self.$store.state.lang
                        }).then(function (response) {
                            self.$store.commit('setCountries', response.data);
                        });

                        self.$axios({
                            method: 'get',
                            url: '/checkin/'+self.$store.state.database+'/getCities'
                        }).then(function (response) {
                            self.$store.commit('setCities', response.data);
                        });

                        self.$axios({
                            method: 'get',
                            url: '/checkin/'+self.$store.state.database+'/getIdentityDocuments'
                        }).then(function (response) {
                            self.$store.commit('setIdentityDocuments', response.data);
                        });
                    } else {
                        self.$store.commit('setStatus', -4);
                    }
                });

                self.$axios({
                    method: 'get',
                    url: '/booking/'+self.$store.state.database+'/getTranslations'
                }).then(function (response) {
                    self.$store.commit('setTranslations', response.data);
                });
            });
        }
    }
</script>

<style>
    * {
        box-sizing: border-box;
    }

    body {
        background-image: var(--backgroundImage);
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-color: var(--backgroundColor);
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        overflow-y: scroll !important;
    }

    .main {
        width: 95%;
        max-width: 700px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0 25px;
        display: flex;
        align-items: self-start;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        border-top: 5px solid var(--mainColor);
    }

    input[type="text"]::-webkit-calendar-picker-indicator,
    input[type="text"]::-webkit-list-button {
        display: none !important;
    }
</style>