<template>
	<form class="guest_data">
		<div class="title">{{ getTranslation('GUEST') }} #{{guest_number}}</div>
		<div class="row">
			<label :for="'surname_' + guest_number"><span class="error"></span>{{ getTranslation('SURNAME') }}</label>
			<input type="text" :id="'surname_' + guest_number" v-model="surname" />
		</div>
		<div class="row">
			<label :for="'name_' + guest_number"><span class="error"></span>{{ getTranslation('NAME') }}</label>
			<input type="text" :id="'name_' + guest_number" v-model="name" />
		</div>
		<div class="row">
			<label :for="'gender_' + guest_number">{{ getTranslation('SEX') }}</label>
			<select :id="'gender_' + guest_number" v-model="gender">
				<option value="M">M</option>
				<option value="F">F</option>
			</select>
		</div>
		<div class="row">
			<label :for="'birthdate_' + guest_number"><span class="error"></span>{{ getTranslation('BIRTHDATE') }}</label>
			<input type="date" :id="'birthdate_' + guest_number" v-model="birthdate" />
		</div>
		<div class="row">
			<label :for="'birthstate_' + guest_number">{{ getTranslation('STATEBIRTH') }}</label>
			<select :id="'birthstate_' + guest_number" v-model="birthstate">
				<option v-for="country in countries" v-bind:key="country.ALPHA2CODE" v-bind:value="country.ALPHA2CODE">{{ country.DESCRIPTION }}</option>
			</select>
		</div>
		<div class="row">
			<label :for="'birthcity_' + guest_number"><span class="error"></span>{{ getTranslation('CITYBIRTH') }}</label>
			<input type="text" :id="'birthcity_' + guest_number" v-model="birthcity" :list="'birthcities_' + guest_number" autocomplete="nope" />
			<datalist :id="'birthcities_' + guest_number">
				<option v-for="(city, index) in birthcities" :key="index" :value="city"></option>
			</datalist>
		</div>
		<div class="row">
			<label :for="'citizenship_' + guest_number">{{ getTranslation('STATECITIZEN') }}</label>
			<select :id="'citizenship_' + guest_number" v-model="citizenship">
				<option v-for="country in countries" v-bind:key="country.ALPHA2CODE" v-bind:value="country.ALPHA2CODE">{{ country.DESCRIPTION }}</option>
			</select>
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'state_' + guest_number">{{ getTranslation('STATERES') }}</label>
			<select :id="'state_' + guest_number" v-model="state">
				<option v-for="country in countries" v-bind:key="country.ALPHA2CODE" v-bind:value="country.ALPHA2CODE">{{ country.DESCRIPTION }}</option>
			</select>
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'city_' + guest_number"><span class="error"></span>{{ getTranslation('CITY') }}</label>
			<input type="text" :id="'city_' + guest_number" v-model="city" :list="'cities_' + guest_number" autocomplete="nope" />
			<datalist :id="'cities_' + guest_number">
				<option v-for="(city, index) in cities" :key="index" :value="city"></option>
			</datalist>
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'address_' + guest_number"><span class="error"></span>{{ getTranslation('ADDRESS') }}</label>
			<input type="text" :id="'address_' + guest_number" v-model="address" />
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'identitydocument_' + guest_number">{{ getTranslation('DOCID') }}</label>
			<select :id="'identitydocument_' + guest_number" v-model="identitydocument">
				<option v-for="identity_document in identity_documents" v-bind:key="identity_document.ID" v-bind:value="identity_document.ID">{{ identity_document.DESCRIPTION }}</option>
			</select>
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'identitydocumentnumber_' + guest_number"><span class="error"></span>{{ getTranslation('DOCNR') }}</label>
			<input type="text" :id="'identitydocumentnumber_' + guest_number" v-model="identitydocumentnumber" />
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :for="'identitydocumentstate_' + guest_number">{{ getTranslation('STATEDOC') }}</label>
			<select :id="'identitydocumentstate_' + guest_number" v-model="identitydocumentstate">
				<option v-for="country in countries" v-bind:key="country.ALPHA2CODE" v-bind:value="country.ALPHA2CODE">{{ country.DESCRIPTION }}</option>
			</select>
		</div>
		<div class="row" v-if="guest_number == 1">
			<label :id="'identitydocumentcity_' + guest_number"><span class="error"></span>{{ getTranslation('IDCITYDOCUMENT') }}</label>
			<input type="text" :id="'identitydocumentcity_' + guest_number" v-model="identitydocumentcity" :list="'documentcities_' + guest_number" autocomplete="nope" />
			<datalist :id="'documentcities_' + guest_number">
				<option v-for="(city, index) in documentcities" :key="index" :value="city"></option>
			</datalist>
		</div>
	</form>
</template>

<script>
	import jQuery from 'jquery'
	let $ = jQuery

	export default {
		name: 'GuestData',
		props: ['guest_number'],
		data() {
			return {
				surname: '',
				name: '',
				gender: 'M',
				birthdate: '',
				birthstate: 'IT',
				birthcity: '',
				citizenship: 'IT',
				state: 'IT',
				city: '',
				address: '',
				identitydocument: 10590,
				identitydocumentnumber: '',
				identitydocumentstate: 'IT',
				identitydocumentcity: ''
			}
		},
		computed: {
			countries: function() {
				return this.$store.state.countries;
			},
			birthcities: function() {
				if(this.birthstate == 'IT') {
					return this.$store.state.cities;
				} else {
					return [];
				}
			},
			cities: function() {
				if(this.state == 'IT') {
					return this.$store.state.cities;
				} else {
					return [];
				}
			},
			documentcities: function() {
				if(this.identitydocumentstate == 'IT') {
					return this.$store.state.cities;
				} else {
					return [];
				}
			},
			identity_documents: function() {
				return this.$store.state.identity_documents;
			},
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
			checkData: function() {
				let all_ok = true;

				if(this.surname == '') {
					$('#surname_'+this.guest_number).addClass('invalid_input');
					$('#surname_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
					all_ok = false;
				}

				if(this.name == '') {
					$('#name_'+this.guest_number).addClass('invalid_input');
					$('#name_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
					all_ok = false;
				}

				if(this.birthdate == '') {
					$('#birthdate_'+this.guest_number).addClass('invalid_input');
					$('#birthdate_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
					all_ok = false;
				}

				if(this.birthcity == '') {
					$('#birthcity_'+this.guest_number).addClass('invalid_input');
					$('#birthcity_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
					all_ok = false;
				} else if(this.birthstate == 'IT') {
					if(!this.birthcities.includes(this.birthcity)) {
						$('#birthcity_'+this.guest_number).addClass('invalid_input');
						$('#birthcity_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
						all_ok = false;
					}
				}

				if(this.guest_number == 1) {
					if(this.city == '') {
						$('#city_'+this.guest_number).addClass('invalid_input');
						$('#city_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
						all_ok = false;
					} else if(this.birthstate == 'IT') {
						if(!this.cities.includes(this.city)) {
							$('#city_'+this.guest_number).addClass('invalid_input');
							$('#city_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
							all_ok = false;
						}
					}

					if(this.identitydocumentnumber == '') {
						$('#identitydocumentnumber_'+this.guest_number).addClass('invalid_input');
						$('#identitydocumentnumber_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
						all_ok = false;
					}

					if(this.identitydocumentcity == '') {
						$('#identitydocumentcity_'+this.guest_number).addClass('invalid_input');
						$('#identitydocumentcity_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
						all_ok = false;
					} else if(this.identitydocumentstate == 'IT') {
						if(!this.documentcities.includes(this.identitydocumentcity)) {
							$('#identitydocumentcity_'+this.guest_number).addClass('invalid_input');
							$('#identitydocumentcity_'+this.guest_number).siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
							all_ok = false;
						}
					}
				}

				return all_ok;
			},
			getData: function() {
				if(this.guest_number == 1) {
					return {
						guest_number: this.guest_number,
						surname: this.surname,
						name: this.name,
						gender: this.gender,
						birthdate: this.birthdate,
						birthstate: this.birthstate,
						birthcity: this.birthcity,
						citizenship: this.citizenship,
						state: this.state,
						city: this.city,
						address: this.address,
						identitydocument: this.identitydocument,
						identitydocumentnumber: this.identitydocumentnumber,
						identitydocumentstate: this.identitydocumentstate,
						identitydocumentcity: this.identitydocumentcity
					};
				} else {
					return {
						guest_number: this.guest_number,
						surname: this.surname,
						name: this.name,
						gender: this.gender,
						birthdate: this.birthdate,
						birthstate: this.birthstate,
						birthcity: this.birthcity,
						citizenship: this.citizenship
					};
				}
			}
		},
	}
</script>

<style type="text/css" scoped>
	.guest_data {
		display: flex;
		flex-direction: column;
		box-shadow: 0 1px 9px rgba(0,0,0,.16);
		padding: 15px;
		border-radius: 6px;
		border-left: 5px solid var(--mainColor);
		margin-top: 30px;
	}

	.title {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 5px;
	}

	.row {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.row label {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 200px;
		text-align: right;
		padding-right: 15px;
		position: relative;
	}

	.row label .error {
		color: #802;
		font-size: 12px;
		margin-right: 5px;
	}

	.row input[type="text"],
	.row input[type="date"] {
		flex: 1;
		border: none;
		outline: none!important;
		box-shadow: 0 3px 6px rgba(0,0,0,.16);
		border-radius: 3px;
		padding: 6px 8px;
		resize: none;
		font-family: Montserrat,sans-serif;
	}

	.row input.invalid_input {
		box-shadow: 1px 1px 6px #88002288 !important;
	}

	.row select {
		flex: 1;
		border: none;
		box-shadow: 0 3px 6px rgba(0,0,0,.16);
		padding: 6px 5px;
		border-radius: 3px;
		font-family: Montserrat,sans-serif;
		outline: none!important;
		max-width: 365px;
	}

	@media only screen and (max-width: 799px) {
		.row {
			flex-direction: column;
			align-items: flex-start;
		}

		.row label {
			text-align: left;
			justify-content: flex-start;
			width: 100%;
		}

		.row input[type="text"],
		.row input[type="date"] {
			width: 100%;
		}

		.row select {
			max-width: 100%;
			width: 100%;
		}
	}
</style>